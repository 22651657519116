/* my-magic-header */
<template>
  <section id="kladno-logo">
    <div class="bg-gray-100 text-white py-2 mb-0">
      <div class="container xl:max-w-screen-xl max-w-screen-lg mx-auto px-4">
        <div class="flex-row md:flex justify-center md:justify-between ">
          <div>
            <div
              class="fb-page h-32"
              data-href="https://www.facebook.com/Herna-Modr%C3%BD-Sv%C4%9Bt-100623669423040"
              data-width="380"
              data-hide-cover="false"
              data-show-facepile="false"
            ></div>
          </div>

          <div>
            <img
              :src="require('@/assets/images/KLADNO-Za-podpory-města.png')"
              class="h-32"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="bg-red-400 text-gray-200">
    <div class="container mx-auto px-6 py-2 text-base xl:max-w-screen-xl max-w-screen-lg">
      <div class="flex-row sm:flex py-2">
        <div class="pr-4">
          <div class="flex items-center list">
            <div>
              <MailOpen size="1.6x" class="stroke-current text-gray-200 mb-2 mr-1" />
            </div>
            <div>
              <a
                :href="'mailto:' + email"
                class="hover:underline hover:text-white text-gray-300"
                >{{ email }}</a
              >
            </div>
          </div>
        </div>
        <div class="pr-4">
          <div class="flex items-center list hover:text-white">
            <div>
              <Phone size="1.6x" class="stroke-current text-gray-200 mb-2 mr-1" />
            </div>
            <div>
              <span>tel:</span>
              <a :href="phoneLink" class="hover:underline">{{ phoneText }}</a>
            </div>
          </div>
        </div>
        <div class="pr-4">
          <div class="flex items-center list hover:text-white">
            <div>
              <LocationMarker
                size="1.6x"
                class="stroke-current text-gray-200 mb-2 mr-1"
              />
            </div>
            <div>{{ address1 }}, {{ address2 }}</div>
          </div>
        </div>
        <div class="pr-4">
          <div class="flex items-center list hover:text-white">
            <div>
              <GlobeAlt size="1.6x" class="stroke-current text-gray-200 mb-2 mr-1" />
            </div>
            <div>
              <div>
                <span>Web:</span>
                <a :href="'https://' + webUrl" target="_blank" class="hover:underline">{{
                  webUrl
                }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pr-4">
          <div class="flex items-center list hover:text-white">
              <div>
                <a href="https://www.facebook.com/Herna-Modr%C3%BD-Sv%C4%9Bt-100623669423040" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 60 60"
                  fill="currentColor"
                  width="1.6em"
                  height="1.6em"
                  class="stroke-current text-gray-200 mb-2 mr-1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
		v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
		v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"
                    clip-rule="evenodd"
                  ></path>
                </svg></a>
              </div>
              <div>
                <a href="https://www.facebook.com/Herna-Modr%C3%BD-Sv%C4%9Bt-100623669423040" target="_blank" class="hover:underline">Sledujte nás na Facebooku</a>
              </div>              
          </div>
        </div>
      </div>

      <div class="py-2">
        <div class="flex justify-between spaceitems-center">
          <div>&copy; {{ year }} {{ webName }}. Všechna práva vyhrazena.</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { LocationMarker, Phone, MailOpen, GlobeAlt } from "vue-next-heroicons/outline";

export default {
  name: "AppFooter",

  components: {
    LocationMarker,
    Phone,
    MailOpen,
    GlobeAlt,
  },

  data: function () {
    return {
      year: new Date().getFullYear(),
      items: [],
      email: this.email,
    };
  },

  computed: {
    phoneLink() {
      return "tel:" + this.phone.replace(" ", "");
    },
    phoneText() {
      return this.phone.replace("+420 ", "");
    },
  },

  mounted() {
    //alert('ahoj');
    this.facebook();
  },
  methods: {
    
    async facebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },

    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          version: "v14.0",
          xfbml: 1,
        });
      };
    },

    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/cs_CZ/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
};
</script>
