/* my-magic-header */
<template>
    <section>
        <div class="px-4  container xl:max-w-screen-xl max-w-screen-lg mx-auto flex justify-between">
            <div class="sm:hidden flex items-center justify-between py-3 sm:p-0">
                <button type="button" class="block px-4 text-gray-800 hover:black focus:text-black focus:outline-none" @click="isOpen = !isOpen">
                            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path
                               v-if="isOpen"
                                  fill-rule="evenodd"
                                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                />
                                <path
                                  v-if="!isOpen"
                                  fill-rule="evenodd"
                                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                                />
                              </svg>
                            </button>
    
            </div>
    
            <nav :class="isOpen ? 'block' : 'hidden'" class="sm:flex pt-4 pb-3 pr-2">
    
                <router-link :to="{  hash: '#onas', name: 'modrysvet' }" class="block uppercase font-bold text-lg p-2 lg:px-3 text-gray-900 rounded hover:text-white hover:bg-pink-500">
                    O nás
                </router-link>
    
                <router-link :to="{  hash: '#mistnosti', name: 'modrysvet' }" class="block uppercase font-bold text-lg p-2 lg:px-3 text-gray-900 rounded hover:text-white hover:bg-pink-500">
                    Místnosti
                </router-link>

                <router-link :to="{  hash: '#fotogalerie', name: 'modrysvet' }" class="block uppercase font-bold text-lg p-2 lg:px-3 text-gray-900 rounded hover:text-white hover:bg-pink-600">
                    Fotogalerie
                </router-link>
    
                <router-link :to="{  hash: '#cenik', name: 'modrysvet' }" class="block uppercase font-bold text-lg p-2 lg:px-3 text-gray-900 rounded hover:text-white hover:bg-pink-700">
                    Ceník
                </router-link>

                <router-link :to="{  hash: '#kontakt', name: 'modrysvet' }" class="block uppercase font-bold text-lg p-2 lg:px-3 text-gray-900 rounded hover:text-white hover:bg-pink-800">
                    Kontakt
                </router-link>
    
            </nav>
        </div>
    </section>
    
    <section class="pt-2 pb-2 bg-blue-400 text-white">
        <div class="container xl:max-w-screen-xl max-w-screen-lg mx-auto px-4 flex items-center">
            <div>
                <router-link :to="{  name: 'modrysvet' }"><img :src="require('@/assets/images/121koule_modra.png')" /></router-link>
            </div>
            <div class="ml-4">
                <h1 class="text-5xl mb-4 font-extrabold">{{ webName }}</h1>
                <div class="uppercase font-bold">Speciální herna pro naše speciální děti</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AppHeader",

    data: function() {
        return {
            isOpen: false,

        };
    },
    computed: {
        address() {
            return this.mapAddress;
        },
        phoneLink() {
            return "tel:" + this.phone.replace(" ", "");
        },
        phoneText() {
            return this.phone.replace("+420 ", "");
        },

    },

    methods: {

    },
};
</script>

