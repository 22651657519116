<template>
  <!-- <div id="app" :style="{ background: `no-repeat fixed center url(${image})` }"> -->
    <div id="app">
    <app-header></app-header>
    <router-view></router-view>
    <back-top />
    <app-footer></app-footer>
    <!-- <app-sign></app-sign> -->
  </div>
</template>

<script>
import AppHeader  from "@/components/appHeader.vue"
import AppFooter  from "@/components/appFooter.vue"
import BackTop from '@/components/backTop.vue'
//import image from '@/assets/images/kulicky.jpg';

export default {
  name: 'app',
  components: {
    AppHeader,AppFooter,
    BackTop
  },
  data() {
      return {
  //        image
      }
   }  
}
</script>

