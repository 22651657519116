/* my-magic-header */

<template>
  <transition name="back-to-top-fade">
    <div v-show="visible"
         class="vue-back-to-top"
         :style="`bottom:${bottom};right:${right};`"
         @click="backToTop">
      <slot>
        <div class="default">
          <ChevronUp size="1.5x" class="text-blue-800" />
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
  import { ChevronUp } from 'vue-next-heroicons/solid'

  export default {
    name: 'BackTop',
    components: { ChevronUp },
    props: {
      text: {
        type: String,
        default: 'Top'
      },
      visibleoffset: {
        type: [String, Number],
        default: 600
      },
      visibleoffsetbottom: {
        type: [String, Number],
        default: 0
      },
      right: {
        type: String,
        default: '0rem'
      },
      bottom: {
        type: String,
        default: '0rem'
      },
      scrollFn: {
        type: Function,
        //default: function(eventObject) {}
        default: function() {}
      }
    },
    data() {
      return {
        visible: false
      }
    },

    mounted() {
      window.smoothscroll = () => {
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
        if (currentScroll > 0) {
          window.requestAnimationFrame(window.smoothscroll)
          window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5))
        }
      }
      window.addEventListener('scroll', this.catchScroll)
    },
    unmounted() {
      window.removeEventListener('scroll', this.catchScroll)
    },
    methods: {
      catchScroll() {
        const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset)
        const pastBottomOffset =
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom)
        this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset
        this.scrollFn(this)
      },
      backToTop() {
        window.smoothscroll()
        this.$emit('scrolled')
      }
    }
  }

</script>

<style scoped>

  @tailwind base;

  .back-to-top-fade-enter-active,
  .back-to-top-fade-leave-active {
    transition: opacity 0.7s;
  }

  .back-to-top-fade-enter,
  .back-to-top-fade-leave-to {
    opacity: 0;
  }

  .vue-back-to-top {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
  }

  .vue-back-to-top .default {
    @apply bg-gray-100;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    font-size: 3rem;
    padding: 0px 0px 0px 0px;
  }

  .vue-back-to-top--is- {
    bottom: 50% !important;
    position: absolute;
  }

</style>
