if ( process.env.NODE_ENV === 'production' ) {
  if(window.location.protocol != 'https:') {
    location.href =   location.href.replace("http://", "https://");
  }
}

import { createApp } from 'vue'

import App from './App.vue'

import router from './router'

import './assets/styles/index.css';

const app = createApp(App).use(router);

app.config.globalProperties.email = 'info@modry-svet.cz';
app.config.globalProperties.webName="Herna Modrý svět";
app.config.globalProperties.webUrl="www.modry-svet.cz";
app.config.globalProperties.address1="Aneta Hrbatová";
app.config.globalProperties.address2="Divadelní 1703, Kladno";
app.config.globalProperties.mapAddress = "Divadelní 1703, Kladno";
app.config.globalProperties.phone = '+420 774271789';



app.mount('#app');

