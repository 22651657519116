import {
  createRouter,
  createWebHistory
} from 'vue-router'

const Modrysvet = ()  => import('@/views/Modrysvet.vue');

const routes = [

  {
    path: '/',
    name: 'modrysvet',
    component: Modrysvet,
  },
//  {
//    path: '/',
//    redirect: "/modrysvet"
//  },
]

const scrollBehavior = function (to, from, savedPosition) {

  if (savedPosition) {
    return savedPosition
  } else {

    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      return { top: 0 }
    }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes
})

export default router
